import React, {FC, useContext, useEffect, useState} from 'react';
import firebase from 'firebase';
import {Client, TrainerRecommendation, Workout, WorkoutStatus} from 'tlm-common';
import {useAuthState} from '../services/firebase/AuthProvider';
import {
  getClientDocument,
  getRecommendationsCollection,
  getWorkoutsCollection,
  markWorkoutComplete,
  commitWorkoutProgress,
  setWorkoutFavorite,
  updateClient,
  updateWorkout
} from '../services/api/clients';
import * as api from '../services/api';
import {Flexidate} from "../services/api/helpers";

type FirebaseUser = firebase.User | null | undefined;

interface ClientState {
  client?: Client;
  updateClient: (c: Client) => Promise<void>;
  workouts: Workout[];
  notStartedWorkouts: Workout[];
  inProgressWorkouts: Workout[];
  favoriteWorkouts: Workout[];
  completedWorkouts: Workout[];
  updateWorkout: (id: string, w: Partial<Workout>) => Promise<void>;
  setWorkoutFavorite: (w: Workout, f: boolean) => Promise<void>;
  trainerRecommendations: TrainerRecommendation[];
  markWorkoutComplete: typeof markWorkoutComplete;
  commitWorkoutProgress: typeof commitWorkoutProgress;
}

const INITIAL_STATE: ClientState = {
  client: undefined,
  updateClient,
  workouts: [],
  notStartedWorkouts: [],
  inProgressWorkouts: [],
  favoriteWorkouts: [],
  completedWorkouts: [],
  updateWorkout,
  setWorkoutFavorite,
  trainerRecommendations: [],
  markWorkoutComplete,
  commitWorkoutProgress
};

const ClientContext = React.createContext<ClientState>(INITIAL_STATE);

const sortWorkoutsByDate = (a: Workout, b: Workout) => api.helpers.sortByTFlexidate( a.createdAt as Flexidate, b.createdAt as Flexidate);

export const ClientProvider: FC = ({children}) => {
  const [client, setClient] = useState<Client | undefined>(undefined);
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [trainerRecommendations, setTrainerRecommendations] = useState<TrainerRecommendation[]>([]);

  const {isLoggedIn} = useAuthState();

  useEffect(() => {
    if (isLoggedIn) {
      const clientDoc = getClientDocument();
      const unsubClient = clientDoc.onSnapshot(clientUpdate);
      const workoutsCollection = getWorkoutsCollection();
      const unsubWorkouts = workoutsCollection.onSnapshot(workoutsUpdate);
      const unsubRecs = getRecommendationsCollection().onSnapshot(recsUpdate);
      return () => {
        unsubClient();
        unsubWorkouts();
        unsubRecs();
      };
    }
  }, [isLoggedIn]);

  const clientUpdate = (doc: firebase.firestore.DocumentSnapshot) => {
    if (!doc) {
      console.error(`@@@WTF?`);
      return;
    }
    console.log(`Client updated`);
    if (doc.metadata.hasPendingWrites) {
      console.log('Update is local, ignoring');
    } else {
      setClient(doc.data() as Client);
    }
  };

  const recsUpdate = (recsSnapshot: firebase.firestore.QuerySnapshot) => {
    if (!recsSnapshot) {
      return;
    }
    const recsData: TrainerRecommendation[] = [];
    recsSnapshot.docs.forEach((w) => {
      const data = {...w.data(), id: w.id};
      recsData.push(data as TrainerRecommendation);
    });
    setTrainerRecommendations(recsData);
    console.log(`${recsData.length} recommendations loaded`);
  }

  const workoutsUpdate = (workoutsSnapshot: firebase.firestore.QuerySnapshot) => {
    if (!workoutsSnapshot) {
      return;
    }

    // if (workoutsSnapshot.metadata.hasPendingWrites) {
    //   console.log('Workout update is local, ignoring');
    // } else {
      const woData: Workout[] = [];
      workoutsSnapshot.docs.forEach((w) => {
        const data = {...w.data(), id: w.id};
        woData.push(data as Workout);
      });
      setWorkouts(woData);
      console.log(`${woData.length} workouts loaded`);
    // }
  }

  const favoriteWorkouts = workouts.filter((wo) => wo.favorite);

  const notStartedWorkouts = workouts
    .filter((wo) => wo.progress!.status === WorkoutStatus.notStarted)
    .sort(sortWorkoutsByDate);

  const inProgressWorkouts = workouts
    .filter((wo) => wo.progress!.status === WorkoutStatus.inProgress)
    .sort(sortWorkoutsByDate);

  const completedWorkouts = workouts
    .filter((wo) => wo.progress!.status === WorkoutStatus.complete)
    .sort(sortWorkoutsByDate);

  return (
    <ClientContext.Provider
      value={{
        client,
        updateClient,
        workouts,
        updateWorkout,
        notStartedWorkouts,
        inProgressWorkouts,
        favoriteWorkouts,
        completedWorkouts,
        setWorkoutFavorite,
        trainerRecommendations,
        markWorkoutComplete,
        commitWorkoutProgress
      }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = () => {
  return useContext(ClientContext);
};
