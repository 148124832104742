import React, {CSSProperties, FC} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { auth } from "../../services/firebase/firebaseCore";
import {useClient} from "../../providers/ClientProvider";
import {acceptWaiver} from "../../services/api/clients";
import { useHistory } from 'react-router-dom';

interface Props {
  show: boolean;
  onAccepted: () => void;
}

export const LiabilityWaiverModal: FC<Props> = ({show, onAccepted}) => {

  const history = useHistory();
  const { client } = useClient();

  const handleCancel = async () => {
    await auth.signOut();
    history.replace('/');
  }

  const handleAgree = async () => {
    await acceptWaiver();
    onAccepted();
  }


  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title><h1>Liability Waiver</h1></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p style={styles.text}>Disclaimer - This website contains information for educational purposes and is not intended to diagnose or treat medical conditions, or replace the advice of your healthcare professional.</p>
        <p style={styles.text}>If you use the information on this website to participate in physical activity you do so at your own risk.
          Use of the information means that you have reviewed and agree to the website terms and conditions, privacy policy and liability waiver.</p>
        {/*<p>{client?.uid}</p>*/}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>Cancel and Logout</Button>
        <Button variant="primary" onClick={handleAgree}>Agree and Continue</Button>
      </Modal.Footer>
    </Modal>
  );
};

const styles ={
  text: {
    fontSize: 20
  } as CSSProperties
};
