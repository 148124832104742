import React, {FC} from 'react';
import {Workout, WorkoutType} from "tlm-common";
import {Badge, Col, Container, Row} from "react-bootstrap";
import {WorkoutCard} from "./WorkoutCard";
import JSONPretty from "react-json-pretty";

interface Props {
  workouts: Workout[],
  title?: string;
  showCount?: boolean;
  onStart: (w: Workout) => void;
  description?: string;
}

export const WorkoutDeck: FC<Props> = ({workouts, title, showCount, onStart, description=""}) => {

  const normalWorkouts = workouts.filter(w=>w.workoutType!==WorkoutType.mobility);
  const mobilityWorkouts = workouts.filter(w=>w.workoutType===WorkoutType.mobility);

  return (
    <Container className="pr-sm-0 pr-md-5">
      <Row>
        <Col>
          {title && <h2 className="antipasto-medium">{title}</h2>}
          {showCount && <Badge variant="secondary" className="mb-3">{workouts.length} workouts available</Badge>}
          {/*<JSONPretty json={workouts}/>*/}
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Regular Workouts</h5>
          {normalWorkouts.length===0 ? <p className="text-muted font-italic">You have no {description} workouts available.</p> : null }
        </Col>
      </Row>
      <Row>
        {normalWorkouts.map((w, idx) => (
          <Col sm={12} md={6} xl={4}>
            <WorkoutCard workout={w}
                         key={idx}
                         onStart={() => onStart(w)}/>
          </Col>
        ))}
      </Row>
      <Row>
        <Col>
          <h5>Mobility Workouts</h5>
          {mobilityWorkouts.length===0 ? <p className="text-muted font-italic">You have no {description} mobility workouts.</p> : null }
        </Col>
      </Row>
      <Row>
        {mobilityWorkouts.map((w, idx) => (
          <Col sm={12} md={6} xl={4}>
            <WorkoutCard workout={w}
                         key={idx}
                         onStart={() => onStart(w)}/>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
