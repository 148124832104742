import React, {FC} from 'react';
import {TrainerRecommendation} from "tlm-common";
import {Badge, Col, Container, Row} from "react-bootstrap";
import {RecommendationCard} from "./RecommendationCard";
import { useHistory } from 'react-router-dom';

interface Props {
  recommendations: TrainerRecommendation[];
  showCount: boolean;
  title: string;
}

export const RecommendationDeck: FC<Props> = ({recommendations, showCount, title}) => {

  const history = useHistory();

  return (
    <Container className="pr-sm-0 pr-md-5">
      <Row>
        <Col>
          {title && <h2 className="antipasto-medium">{title}</h2>}
          {showCount &&
          <Badge variant="secondary" className="mb-3">{recommendations.length} recommendation{recommendations.length>1 ? 's':''} available</Badge>}
        </Col>
      </Row>
      <Row>
        {recommendations.map(r =>
          <Col sm={12} md={6} xl={4} key={r.id}>
            <RecommendationCard recommendation={r} key={r.id} onClick={() => history.push(`/playmedia/${r.id}`)}/>
          </Col>)}
      </Row>
    </Container>
  );
};
