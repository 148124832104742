import React, {CSSProperties, FC, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {GCSFile, Timestamp, TrainerRecommendation} from 'tlm-common';
import {gcsUrlForFileName} from "../../services/gcf/gcsUrlForFileName";
import {Card, Col, Row} from "react-bootstrap";
import {ClientPortalContainer} from "../../components/layout/ClientPortalContainer";
import * as api from '../../services/api';
import {timestampToFormattedDate} from "../../services/api/helpers";
import {SlickVideo} from "../../components/video/SlickVideo";

// because of no typescript, we have to do the require
//const {Player, BigPlayButton, ControlBar, ReplayControl, ForwardControl} = require('video-react');

export const PlayMediaScreen: FC = () => {

  const [rec, setRec] = useState<TrainerRecommendation | undefined>();
  const {id} = useParams<{ id: string }>();

  useEffect(() => {
    async function loadRec(id: string) {
      const r = await api.clients.getRecommendation(id);
      setRec(r);
    }

    if (id) {
      loadRec(id);
    }
  }, [id]);

  if (!rec) return null;

  const videoSource = gcsUrlForFileName((rec!.media!.video as GCSFile).name);

  return (
    <ClientPortalContainer>
      <Row>
        <Col>
          <div style={{margin: 20}}>
            <SlickVideo url={videoSource} onEnded={()=>console.log('done')} play={true}/>
            <h4>{rec.body}</h4>
            <p className="text-muted small">
              From {rec.createdBy} on {timestampToFormattedDate(rec.createdOn as Timestamp)}
            </p>
          </div>
        </Col>
      </Row>
    </ClientPortalContainer>
  );
};

const styles = {
  container: {
    backgroundColor: 'black',
    width: '100vw',
    height: '100vw',
    marginTop: 0,
    color: 'white'
  },
  video: {
    width: '100%'
  },
  topLeft: {
    position: 'absolute',
    top: 5,
    left: 5,
    fontSize: '5vh',
    opacity: 0.8,
    color: '#ffffff',
    fontWeight: 'bold'
  } as CSSProperties,
  topRight: {
    position: 'absolute',
    top: 5,
    right: 5,
    fontSize: '5vh',
    color: '#ffffff',
    opacity: 0.8,
    fontWeight: 'bold'
  } as CSSProperties
}
