import React, {CSSProperties, FC} from 'react';
import {Button} from "react-bootstrap";
import {THEME_COLORS} from "../../assets/styles/themecolors";

interface Props {
  name?: string;
  description?: string;
  onClick(): void;
}

export const CustomExerciseCard: FC<Props> = ({name, description, onClick}) => {

  return (
    <div style={styles.container} className="m-3 p-3">
      <h4>Custom Exercise: {name}</h4>
      <p>{description}</p>
      <Button variant="secondary" onClick={onClick} className="mt-3">Done, move to next exercise.</Button>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: THEME_COLORS.darkPurple,
    color: 'white',
    borderRadius: 5,
  },
  name: {
    color: '#ffffff',
    textAlign: 'center',
    fontWeight: 'bold',
    position: 'absolute',
    top: 10,
    left: '40%',
    right: '40%',
    fontSize: '0.1rem',
    margin: 'auto',
    padding: '5px 10px 5px 10px',
    borderRadius: 5,
    backgroundColor: 'rgba(0,0,0,0.3)'
  } as CSSProperties
}
