import firebase from "firebase";
import moment from "moment";

export type Flexidate = firebase.firestore.Timestamp | string;

export const flexidateToUnixTime = (flexitime: Flexidate): number => {
  console.log('Flexitime: ', typeof flexitime);
  if (!flexitime) return 0;
  if (typeof flexitime === 'string'){
    return new Date(flexitime).getTime();
  } else {
    // fixme hack!
    if (flexitime.seconds){
      return new Date(flexitime.seconds).getTime();
    } else {
      console.warn('unknown object sent to flexitime');
      return 0;
    }
  }
}

export function timestampToFormattedDate( timestamp: Flexidate) {
  if (!timestamp) return null;
  if (typeof timestamp === 'string'){
    return moment(new Date(timestamp)).format('MMM D, YYYY');
  } else {
    return moment(timestamp.seconds*1000).format('MMM D, YYYY');
  }
}

export function sortByTimestampInc( a: firebase.firestore.Timestamp, b: firebase.firestore.Timestamp){
  return a.seconds - b.seconds
}

export function sortByTimestampDec( a: firebase.firestore.Timestamp, b: firebase.firestore.Timestamp){
  return b.seconds - a.seconds
}

export function sortByTFlexidate( a: Flexidate, b: Flexidate){
  console.log('***')
  const delta = flexidateToUnixTime(b) - flexidateToUnixTime(a);
  console.log('>>>', delta);
  return delta;
}
