import * as exercises from './exercises';
import * as clients from './clients';
import * as helpers from './helpers';
import * as users from './users';
import * as gcsMedia from '../gcf/gcsMedia';
import * as firestoreMedia from './media/firestoreMedia';

const media = {...gcsMedia, ...firestoreMedia};

export {
  exercises,
  clients,
  helpers,
  users,
  media
}
