import React, {CSSProperties, FC, useState} from 'react';
import {ClientPortalNavbar} from "../navigation/ClientPortalNavbar";
import {Nav} from "react-bootstrap";
import workoutIcon from '../../assets/images/workout.png';
import fundamentalsIcon from '../../assets/images/fundamentals.png';
import profileIcon from '../../assets/images/profile.png';

const SHOW_BOTTOM_BAR = false;

interface Props {
}

export const ClientPortalContainer: FC<Props> = ({children}) => {
  const [tabKey, setTabKey] = useState('workout');

  return (
    <div>
      <ClientPortalNavbar/>
      {children}
      { SHOW_BOTTOM_BAR ?
      <div className="d-sm-none fixed-bottom" style={styles.phoneTabContainer}>
        <Nav className="d-flex justify-content-around mr-4" defaultActiveKey={'workout'}
             onSelect={(key, _) => setTabKey(key!)}>
          <Nav.Link eventKey="workout">
            <div className="d-flex flex-column" style={{opacity: tabKey==='workout' ? 1 : 0.5}}>
              <img src={workoutIcon} alt={'workout'} style={styles.tabIcon}/>
              <div style={styles.phoneTab}>
                WORKOUT
              </div>
            </div>
          </Nav.Link>
          <Nav.Link eventKey="fundamentals">
            <div className="d-flex flex-column" style={{opacity: tabKey==='fundamentals' ? 1 : 0.5}}>
              <img src={fundamentalsIcon} alt={'fundamentals'} style={styles.tabIcon}/>
              <div style={styles.phoneTab}>FUNDAMENTALS</div>
            </div>
          </Nav.Link>
          <Nav.Link eventKey="profile">
            <div className="d-flex flex-column" style={{opacity: tabKey==='profile' ? 1 : 0.5}}>
              <img src={profileIcon} alt={'profile'} style={styles.tabIcon}/>
              <div style={styles.phoneTab}>PROFILE</div>
            </div>
          </Nav.Link>
        </Nav>
      </div> : null }
    </div>
  );
};

const styles = {
  phoneTab: {
    fontSize: '0.8rem',
    fontWeight: 'normal',
    opacity: 0.6,
    paddingBottom: 0,
  } as CSSProperties,
  phoneTabContainer: {
    boxShadow: '0px -3px 12px -1px rgba(0,0,0,0.42)',
    height: 80,
    backgroundColor: '#f0f0f0',
    borderTop: '1px solid #f0f0f0',
    paddingBottom: 2
  },
  phoneTabSelected: {
    fontSize: '0.8rem',
    fontWeight: 'normal',
    borderBottom: '3px solid purple',
    paddingBottom: 0
  } as CSSProperties,
  tabIcon: {
    width: 20,
    height: 20,
    alignSelf: 'center'

  }
}

