import React, {FC, useState} from 'react';
import logo from "../../assets/images/tlm.png";
import {Button, Form, Modal} from "react-bootstrap";
import {auth} from "../../services/firebase/firebaseCore";

interface Props {
  onLoginClicked: (email?: string) => void;
}

export const ResetBox: FC<Props> = ({ onLoginClicked }) => {
  const [email, setEmail] = useState('');
  const [errorModalMsg, setErrorModalMsg] = useState<string | null>(null);
  const [ done, setDone ] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(`${email} has requested reset`);
    try {
      await auth.sendPasswordResetEmail(email);
      setDone(true);
      console.log('Signed in!');
    } catch (e) {
      console.log('No bueno');
      console.log(e);
      setErrorModalMsg(e.code);
    }
  }

  return (
    <div className="d-flex p-3 flex-column align-self-center">
      <div className="d-flex p-3 flex-column align-self-center" style={styles.loginBox}>
        <img src={logo} style={styles.logo}/>
        { !done ?
          <div>
            <h4 className="text-muted text-center mt-5">Hey, it happens! Please enter your email below. You will be sent a password reset link.</h4>
            <Form className="mt-3" onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Enter email" onChange={event => {
                  setEmail(event.target.value)
                }}/>
              </Form.Group>
              <div className="float-right">
                <Button variant="link" onClick={() => onLoginClicked(email)}>Back to login...</Button>
              </div>
              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </div> :
          <div>
            <h4 className="text-muted text-center mt-5">Please check your email for a reset link.</h4>
            <Button variant="link" onClick={() => onLoginClicked(email)}>Back to login...</Button>
          </div> }

      </div>
      <Modal show={!!errorModalMsg} onHide={() => setErrorModalMsg(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error Logging In</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorModalMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setErrorModalMsg(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const styles = {
  page: {
    height: '100vh',
    padding: 10
  },
  loginBox: {
    backgroundColor: '#f2edff',
    borderRadius: 5,
    //minWidth: '90%',
    width: 600,
    height: 430,
    // border: '1px solid purple'
  },
  logo: {
    margin: '0 auto',
    width: '200px'
  },
}
