import React, {FC, useEffect, useState} from 'react';
import {ClientType, GCSFile, Media} from "tlm-common";
import {Badge, Button, Col, Container, Modal, Row} from "react-bootstrap";
import * as api from '../../services/api';
import {MediaCard} from "./MediaCard";
import {gcsUrlForFileName} from "../../services/gcf/gcsUrlForFileName";
import {BsFillXCircleFill} from "react-icons/all";
import workoutPic from '../../assets/images/intro5@3x.jpg';
import {useClient} from "../../providers/ClientProvider";
import {SlickVideo} from "../video/SlickVideo";
// because of no typescript, we have to do the require
// const {Player, BigPlayButton, ControlBar, ReplayControl, ForwardControl} = require('video-react');

interface Props {
  title?: string;
  showCount?: boolean;
}

export const EducationDeck: FC<Props> = ({title, showCount = true}) => {

  const [media, setMedia] = useState<Media[]>([]);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState<Media | undefined>();
  const [showPayUp, setShowPayUp] = useState(false);
  const { client } = useClient();

  useEffect(() => {
    async function l() {
      try {
        const m = await api.media.getAllMedia('education');
        setMedia(m);
      } catch (e) {
        console.error(e.message);
      } finally {
        setLoading(false);
      }
    }

    l();
  }, []);

  const handleStart = (m: Media) => {
    console.log(`Play ${m.name}`);
    if (client?.clientType !== ClientType.active){
      setShowPayUp(true);
    } else {
      setPlaying(m);
    }
  }

  return (
    <>
      <Container className="pr-sm-0 pr-md-5">
        <Row>
          <Col>
            {title && <h2 className="antipasto-medium">{title}</h2>}
            {showCount && <Badge variant="secondary" className="mb-3">{media.length} videos available</Badge>}
          </Col>
        </Row>
        <Row>
          {media.length === 0 ?
            <Col>
              <p className="text-muted font-italic">No videos available.</p>
            </Col> : null}
          {!playing ? media.map((m, idx) => (
              <Col sm={12} md={6} xl={4} key={idx}>
                <MediaCard media={m} onStart={handleStart}/>
              </Col>
            )) :
            <>
              <SlickVideo
                url={gcsUrlForFileName((playing!.video as GCSFile).name)}
                onEnded={() => setPlaying(undefined)}
                play={true} />
              {/*<Player src={gcsUrlForFileName((playing!.video as GCSFile).name)}*/}
              {/*        onEnded={() => setPlaying(undefined)}*/}
              {/*        autoPlay>*/}
              {/*  <BigPlayButton position="center"/>*/}
              {/*  <BsFillXCircleFill style={{position: 'absolute', right: 20, top: 20, zIndex: 10000, fontSize: '3em'}}*/}
              {/*                     onClick={() => setPlaying(undefined)}/>*/}
              {/*  <ControlBar autoHide={true}>*/}
              {/*    <ReplayControl seconds={10} order={2.2}/>*/}
              {/*    <ForwardControl seconds={10} order={3.2}/>*/}
              {/*  </ControlBar>*/}
              {/*</Player>*/}
            </>
          }
        </Row>
      </Container>
      <Modal show={showPayUp} onHide={() => setShowPayUp(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Clients Only, Sorry!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={workoutPic} className="w-100"/>
          <p>Educational videos are only available to clients of The Lotus Method.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPayUp(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
