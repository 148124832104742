import React, {CSSProperties, FC, MouseEvent} from 'react';
import {Exercise, GCSFile, Timestamp, TrainerRecommendation, Workout} from "tlm-common";
import {Card} from 'react-bootstrap';
import {gcsUrlForFileName} from "../../services/gcf/gcsUrlForFileName";
import {timestampToFormattedDate} from "../../services/api/helpers";
import {BsFillHeartFill, BsFillPlayFill, BsHeart} from 'react-icons/bs';
import {useClient} from "../../providers/ClientProvider";
import {on} from "cluster";

interface Props {
  recommendation: TrainerRecommendation;
  onClick: (tr: TrainerRecommendation) => void;
}

export const RecommendationCard: FC<Props> = ({recommendation, onClick}) => {

  return (
    <Card className="mr-sm-0 mr-md-2 mb-3 selectable" >
      { recommendation.recommendationType === 'video' &&
        <Card.Img variant="top" src={gcsUrlForFileName((recommendation.media?.thumbnail as GCSFile).name)}
                  onClick={() => onClick(recommendation)}/>
      }
      <Card.Body>
        <Card.Text>{recommendation.body}</Card.Text>
        <Card.Text className="text-muted small">
          From {recommendation.createdBy} on {timestampToFormattedDate(recommendation.createdOn as Timestamp)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const styles = {
  playButton: {
    fontSize: 100,
    color: '#ffffff',
    alignSelf: 'center',
    marginBottom: 20,
    opacity: 0.8
  } as CSSProperties,
  heartFill: {
    color: '#de6363',
    fontSize: 20,
    position: 'absolute',
    top: 20,
    right: 20
  } as CSSProperties,
  heart: {
    color: 'white',
    fontSize: 20,
    position: 'absolute',
    top: 20,
    right: 20
  } as CSSProperties,
  playHolder: {
    //border: '1px solid red',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  } as CSSProperties
}
