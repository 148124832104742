/*********************************
 File:       WorkoutSequencerScreen.tsx
 Function:   Runs the full screen video workout
 Copyright:  The Lotus Method
 Date:       2020-11-09
 Author:     mkahn
 **********************************/
import React, {CSSProperties, FC, useEffect, useReducer, useRef, useState} from 'react';
import {INITIAL_WORKOUT_STATE, workoutSeqReducer, WorkoutState,} from './workoutSequencerReducer';
import {useHistory, useParams} from "react-router-dom";
import {Workout, WorkoutPlaylistEntryType, WorkoutStatus} from 'tlm-common';
import {useClient} from "../../providers/ClientProvider";
import _ from 'lodash';
import {gcsUrlForFileName} from "../../services/gcf/gcsUrlForFileName";
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {ClientPortalContainer} from "../../components/layout/ClientPortalContainer";
import {WorkoutOverview} from "../../components/workoutOverview/WorkoutOverview";
import {SlickVideo} from "../../components/video/SlickVideo";
import {CustomExerciseCard} from "./CustomExerciseCard";
import {setCurrentWorkoutProgress} from "../../services/api/clients";

const SHOW_TITLE = false;

interface Props {
  // used only during development
  mock: boolean;
}

export const WorkoutSequencerScreen: FC<Props> = ({mock = false}) => {
  const {client, workouts, markWorkoutComplete, commitWorkoutProgress} = useClient();
  const {id} = useParams<{ id: string }>();
  const [play, setPlay] = useState(false);
  const [state, dispatch] = useReducer(
    workoutSeqReducer,
    INITIAL_WORKOUT_STATE,
  );
  const currentWorkout = _.find(workouts, {id});
  const [videoSource, setVideoSource] = useState('');
  const history = useHistory();
  const rounds = useRef(0);
  //const progressRef = useRef(0);

  const {
    playlist,
    index,
    showingInstructions,
    workoutDone,
    abandoned,
  } = state;

  useEffect(() => {
    console.log(`useEffect for current workout`);
    if (currentWorkout) {
      dispatch({type: 'SET_WORKOUT', workout: currentWorkout as Workout, isPregnant: client!.isPregnant});
    }
  }, [currentWorkout]);

  useEffect(() => {
    console.log(`useEffect for index`);
    const video = playlist[index];
    if (!video) {
      return;
    }
    playCurrentVideo();

  }, [index]);

  useEffect(() => {
    console.log(`useEffect for playlist`);
    if (playlist.length) {
      playCurrentVideo();
    }
  }, [playlist]);

  useEffect(() => {
    console.log('MOUNT use effect');
    return () => {
      // fixme this is not working because the index is captured when the closure is created
      commitWorkoutProgress(currentWorkout!)
        .then(() => {
          console.log('Progress marked');
        })
      console.log('DISMOUNT')
    };
  }, []);

  const recordDoneAndProceed = async (anotherRound: boolean) => {
    setCurrentWorkoutProgress({
      status: WorkoutStatus.complete,
      exerciseIndex: 0,
      playbackTime: 0,
      rounds: rounds.current || 0
    });
    //await markWorkoutComplete(currentWorkout!);
    if (anotherRound) {
      rounds.current = rounds.current + 1;
      dispatch({ type: 'SET_VIDEO_IDX', index: 0});
      playCurrentVideo();
    } else {
      history.goBack();
    }
  }


  const playCurrentVideo = () => {
    const video = playlist[index];
    if (!video) {
      return;
    }

    // todo: this is really dirty. Need to rewrite state machine to deliver proper states like
    // { type: 'web-video', url: 'https://bdbdbd'} or { type: 'custom', name:, description: }
    if (playlist[index].type === WorkoutPlaylistEntryType.CUSTOM) {
      console.log('Custom exercise')
      setVideoSource('@@@CUSTOM');
    } else {
      setVideoSource(gcsUrlForFileName(video.videoSourceName || ''));
    }
  };

  // const toggleInstructions = () => {
  //   dispatch({ type: 'SHOW_INSTRUCTIONS', isShowing: !showingInstructions });
  // };

  const handleVideoDismissed = () => {
    console.log('Video Dismissed');
    //stop();
    dispatch({type: 'ABANDON'});
  };

  const showNextVideo = () => {
    console.log('Video ended/skipped');
    setCurrentWorkoutProgress({
      status: WorkoutStatus.inProgress,
      exerciseIndex: index,
      playbackTime: 0,
      rounds: rounds.current
    });
    dispatch({type: 'NEXT_VIDEO'});
  };

  const handleVideoError = (err: any) => {
    console.error(err);
    console.error(`${playlist[index].name} errored.`);
    dispatch({type: 'SET_VIDEO_ERROR', isVideoError: true});
  };

  const updateProgress = async () => {
    console.log('Updating workout progress');
    // the below was causing a bug because the update re-rendered and RESTARTED the workout
    //await markWorkoutProgress(currentWorkout!, index);
  }

  const goBack = () => {
    dispatch({type: 'PREV_VIDEO'});
  }

  const goForward = () => {
    dispatch({type: 'NEXT_VIDEO'});
  }

  const goToIndex = (idx: number) => {
    dispatch({type: 'SET_VIDEO_IDX', index: idx});
  }

  const currentExercise = currentWorkout?.exercises[index];
  const exerciseNote = currentWorkout?.exercises[index].clientNotes || '';

  return (
    <ClientPortalContainer>
      <Container>
        <Row>
          <Col>
            {videoSource === '@@@CUSTOM' ?
              <CustomExerciseCard name={currentExercise?.name} description={currentExercise?.description}
                                  onClick={showNextVideo}/> :
              <SlickVideo url={videoSource} onEnded={showNextVideo} play={play && !workoutDone} onStartPressed={() => setPlay(true)}/>}
            {exerciseNote ? <p className="text-muted"
                               style={{
                                 textAlign: 'center', padding: 5, backgroundColor: '#e0e0e0',
                                 borderRadius: 5, marginLeft: 20, marginRight: 20
                               }}>{exerciseNote}</p> : null}
            {currentWorkout && currentWorkout.clientNotes &&
            <div className="floatingcard m-3 animate__animated animate__slideInRight">
              <h5>Workout Notes</h5>
              <p className="text-muted">{currentWorkout!.clientNotes}</p>
            </div>}
          </Col>
          <Col className="mt-3" sm={12} lg={{span: 5, order: 'first'}}>
            {/*<p>Index: {index}</p>*/}
            <div className="animate__animated animate__fadeInLeft">
              <WorkoutOverview workout={currentWorkout} index={index} onClick={goToIndex}/>
              {/*<p>{JSON.stringify(videoSource)}</p>*/}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={workoutDone}>
        <Modal.Header closeButton>
          <Modal.Title>Nice Job!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you finished the workout!!</Modal.Body>
        <Modal.Body>Got enough in the tank for a second round?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => recordDoneAndProceed(true)}>
            Let's Do Another Round!
          </Button>
          <Button variant="secondary" onClick={() => recordDoneAndProceed(false)}>
            I'm Done for Today
          </Button>
        </Modal.Footer>
      </Modal>
    </ClientPortalContainer>
  );
};

const styles = {
  container: {
    backgroundColor: 'black',
    width: '100vw',
    height: '100vw',
    marginTop: 0,
    color: 'white'
  },
  video: {
    width: '100%'
  },
  topLeft: {
    position: 'absolute',
    top: 5,
    left: 5,
    fontSize: '5vh',
    opacity: 0.8,
    color: '#ffffff',
    fontWeight: 'bold'
  } as CSSProperties,
  topRight: {
    position: 'absolute',
    top: 5,
    right: 5,
    fontSize: '5vh',
    color: '#ffffff',
    opacity: 0.8,
    fontWeight: 'bold'
  } as CSSProperties,
  center: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)'
  } as CSSProperties,
  name: {
    color: '#ffffff',
    textAlign: 'center',
    fontWeight: 'bold',
    position: 'absolute',
    top: 10,
    left: '40%',
    right: '40%',
    fontSize: '0.1rem',
    margin: 'auto',
    padding: '5px 10px 5px 10px',
    borderRadius: 5,
    backgroundColor: 'rgba(0,0,0,0.3)'
  } as CSSProperties
}
