import React, {FC, useState} from 'react';
import {LoginBox} from "./LoginBox";
import {ResetBox} from "./ResetBox";

interface OwnProps {
}

type Props = OwnProps;

export const LoginPage: FC<Props> = (props) => {

  const [resetMode, setResetMode] = useState(false);

  return (
    <div className="d-flex flex-xl-column justify-content-center" style={styles.page}>
      { resetMode ?
        <ResetBox onLoginClicked={()=>setResetMode(false)} /> :
        <LoginBox onResetClicked={()=>setResetMode(true)} />
      }
    </div>
  );

};

const styles = {
  page: {
    height: '100vh',
    padding: 10
  }
}

