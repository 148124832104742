import React, {CSSProperties, FC, useState} from 'react';
import {Exercise, Timestamp, Workout} from "tlm-common";
import {Card} from 'react-bootstrap';
import {gcsUrlForFileName} from "../../services/gcf/gcsUrlForFileName";
import {timestampToFormattedDate} from "../../services/api/helpers";
import {BsFillHeartFill, BsFillPlayFill, BsHeart} from 'react-icons/bs';
import {useClient} from "../../providers/ClientProvider";
import exImg from '../../assets/images/exercise.png';

interface Props {
  workout: Workout;
  title?: string;
  onStart: (w: Workout) => void;
}

export const WorkoutCard: FC<Props> = ({workout, title, onStart}) => {
  const [isFav, setFav] = useState<boolean>(workout.favorite || false);
  const {setWorkoutFavorite} = useClient();

  const {exercises} = workout;
  const firstE = exercises[0];
  const {prenatalThumb, postnatalThumb} = firstE as Exercise;
  const { client } = useClient();

  // this weirdness handles if a thumbnail is missing
  const thumbSourceName =  client?.isPregnant ?
    prenatalThumb.name || postnatalThumb.name
    : postnatalThumb.name || prenatalThumb.name;

  const fullThumbSource = firstE.isCustom ? exImg : gcsUrlForFileName(thumbSourceName);

  const toggleFavorite = async (event:  React.MouseEvent<SVGElement, globalThis.MouseEvent>) => {
    event.preventDefault();
    // have to handle this locally because of how Firestore does updates
    setFav(!isFav);
    await setWorkoutFavorite(workout, !workout.favorite);
  }

  return (
    <Card className="mr-sm-0 mr-md-2 mb-3">
      <div style={{position: 'relative'}}>
        <img src={fullThumbSource} className="w-100"/>
        <div style={styles.playHolder}>
          <BsFillPlayFill style={styles.playButton} onClick={() => onStart(workout)}/>
        </div>
        {!isFav && <BsHeart style={styles.heart} onClick={toggleFavorite}/>}
        {isFav &&
        <BsFillHeartFill style={styles.heartFill} className="float-right" onClick={toggleFavorite}/>}
      </div>
      <Card.Body onClick={() => onStart(workout)}>
        {title && <Card.Title>{title}</Card.Title>}
        {workout.name ? <Card.Title><span className="font-italic">{workout.name}</span></Card.Title> : null}
        <Card.Text className="text-muted">
          From {workout.createdBy} on {timestampToFormattedDate(workout.createdAt as Timestamp)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const styles = {
  playButton: {
    fontSize: 100,
    color: '#ffffff',
    alignSelf: 'center',
    marginBottom: 20,
    opacity: 0.8
  } as CSSProperties,
  heartFill: {
    color: '#de6363',
    fontSize: 20,
    position: 'absolute',
    top: 20,
    right: 20
  } as CSSProperties,
  heart: {
    color: 'white',
    fontSize: 20,
    position: 'absolute',
    top: 20,
    right: 20
  } as CSSProperties,
  playHolder: {
    //border: '1px solid red',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  } as CSSProperties
}
