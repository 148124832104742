import React, {CSSProperties, FC} from 'react';
import ReactPlayer from "react-player/lazy";
import {Button} from "react-bootstrap";
import { StartWorkoutButton } from '../buttons/StartWorkoutButton';

interface Props {
  url: string;
  onEnded: () => void;
  play: boolean;
  title?: string;
  onStartPressed?: () => void;
}

export const SlickVideo: FC<Props> = ({url, onEnded, play, title = '', onStartPressed }) => {

  if (!url) return null;

  console.log(`===> Slick rendered: ${url}`);

  return (
    <div style={{position: 'relative', margin: 20}}>
      <ReactPlayer
        url={url}
        controls={true}
        onEnded={onEnded}
        width="100%"
        height="100%"
        playing={play}
      />
      { title ? <p style={styles.name}>{title}</p> : null }
      {/*<BsSkipStart style={styles.topLeft} onClick={goBack}/>*/}
      {/*<BsSkipEnd style={styles.topRight} onClick={goForward}/>*/}
      {/*onClick={() => setPlay(true)}/>*/}
      { play ? null : <div style={styles.center}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          { onStartPressed ?
          //   <Button onClick={() => onStartPressed()} style={{ backgroundColor: 'red' }}>
          //   Start Workout!
          // </Button>
            <StartWorkoutButton onClick={onStartPressed}/>
            : null }
        </div>
      </div> }
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'black',
    width: '100vw',
    height: '100vw',
    marginTop: 0,
    color: 'white'
  },
  video: {
    width: '100%'
  },
  topLeft: {
    position: 'absolute',
    top: 5,
    left: 5,
    fontSize: '5vh',
    opacity: 0.8,
    color: '#ffffff',
    fontWeight: 'bold'
  } as CSSProperties,
  topRight: {
    position: 'absolute',
    top: 5,
    right: 5,
    fontSize: '5vh',
    color: '#ffffff',
    opacity: 0.8,
    fontWeight: 'bold'
  } as CSSProperties,
  center: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 5,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.4)'
  } as CSSProperties,
  name: {
    color: '#ffffff',
    textAlign: 'center',
    fontWeight: 'bold',
    position: 'absolute',
    top: 10,
    left: '40%',
    right: '40%',
    fontSize: '0.1rem',
    margin: 'auto',
    padding: '5px 10px 5px 10px',
    borderRadius: 5,
    backgroundColor: 'rgba(0,0,0,0.3)'
  } as CSSProperties
}
