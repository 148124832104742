import React, {CSSProperties, FC, useEffect, useState} from 'react';
import {useClient} from "../../providers/ClientProvider";
import {Badge, Col, Nav, Row, Tab} from "react-bootstrap";
import {auth} from '../../services/firebase/firebaseCore';
import {Workout} from "tlm-common";
import {useHistory} from 'react-router-dom';
import {ClientPortalContainer} from "../../components/layout/ClientPortalContainer";
import {WorkoutDeck} from "../../components/cards/WorkoutDeck";
import {VerticalGap} from "../../components/layout/VerticalGap";
import {RecommendationDeck} from "../../components/cards/RecommendationDeck";
import {EducationDeck} from "../../components/cards/EducationDeck";
import {LiabilityWaiverModal} from "../../components/modals/LiabilityWaiverModal";

export const ClientLandingPage: FC = () => {
  const {workouts, inProgressWorkouts, favoriteWorkouts, notStartedWorkouts, trainerRecommendations, completedWorkouts, client} = useClient();
  const history = useHistory();
  const [tabKey, setTabKey] = useState('new');
  const [showLiabilityModal, setShowLiabilityModal] = useState(false);

  useEffect(() => {
    if (client && client.hasAcceptedLiabilityWaiver){
      setShowLiabilityModal(false);
    } else if (client && !client.hasAcceptedLiabilityWaiver){
      setShowLiabilityModal(true);
    }
  }, [client]);

  const handleLogout = async () => {
    await auth.signOut();
  }

  const handleStart = (w: Workout) => {
    history.push(`/playworkout/${w.id}`);
  }

  return (
    <ClientPortalContainer>
      <VerticalGap gapSize={30}/>
      <Tab.Container defaultActiveKey={'new'} onSelect={(key, _) => setTabKey(key!)}>
        <div className="d-sm-none fixed-top d-flex justify-content-around" style={styles.phoneTabContainer}>
          <Nav className="d-flex justify-content-between">
            <Nav.Link eventKey="new" style={tabKey === 'new' ? styles.phoneTabSelected : styles.phoneTab}>NEW</Nav.Link>
            <Nav.Link eventKey="inprogress" style={tabKey === 'inprogress' ? styles.phoneTabSelected : styles.phoneTab}>IN
              PROGRESS</Nav.Link>
            <Nav.Link eventKey="recs" style={tabKey === 'recs' ? styles.phoneTabSelected : styles.phoneTab}>FOR
              YOU</Nav.Link>
            <Nav.Link eventKey="favorites"
                      style={tabKey === 'favorites' ? styles.phoneTabSelected : styles.phoneTab}>FAVS</Nav.Link>
            <Nav.Link eventKey="history"
                      style={tabKey === 'history' ? styles.phoneTabSelected : styles.phoneTab}>HISTORY</Nav.Link>
            <Nav.Link eventKey="edu"
                      style={tabKey === 'edu' ? styles.phoneTabSelected : styles.phoneTab}>VIDS</Nav.Link>
          </Nav>
        </div>
        <Row>
          <Col sm={2} className="mt-0 d-none d-sm-block">
            <Nav variant="pills" style={{fontSize: 12}} className="flex-column m-2">
              <Nav.Item>
                <Nav.Link eventKey="new">New</Nav.Link>
              </Nav.Item>
              {inProgressWorkouts.length === 0 ? null :
                <Nav.Item>
                  <Nav.Link eventKey="inprogress">In Progress</Nav.Link>
                </Nav.Item>}
              {trainerRecommendations.length === 0 ? null :
                <Nav.Item>
                  <Nav.Link eventKey="recs">Recommended for You
                    <Badge variant="secondary" className="ml-2">{trainerRecommendations.length}</Badge></Nav.Link>
                </Nav.Item>
              }
              <Nav.Item>
                <Nav.Link eventKey="favorites">Favorites</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="history">History</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="edu">Education</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10} className="mt-4">
            <Tab.Content>
              {inProgressWorkouts.length !== 0 &&
              <Tab.Pane eventKey="inprogress">
                <WorkoutDeck workouts={inProgressWorkouts} onStart={handleStart} title={'In Progress'} showCount
                             description="in progress"/>
              </Tab.Pane>}
              <Tab.Pane eventKey="new">
                <WorkoutDeck workouts={notStartedWorkouts} onStart={handleStart} title={'New Workouts'} showCount
                             description="new"/>
              </Tab.Pane>
              <Tab.Pane eventKey="recs">
                <RecommendationDeck recommendations={trainerRecommendations} title={'Recommendations and Messages'}
                                    showCount/>
              </Tab.Pane>
              <Tab.Pane eventKey="favorites">
                <WorkoutDeck workouts={favoriteWorkouts} onStart={handleStart} title={'Favorites'} showCount
                             description="favorite"/>
              </Tab.Pane>
              <Tab.Pane eventKey="history">
                <WorkoutDeck workouts={completedWorkouts} onStart={handleStart} title={'Workouts History'} showCount
                             description="historical"/>
              </Tab.Pane>
              <Tab.Pane eventKey="edu">
                <EducationDeck title="Educational Videos"/>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <LiabilityWaiverModal show={showLiabilityModal} onAccepted={() => setShowLiabilityModal(false)}/>
    </ClientPortalContainer>
  );
};

const styles = {
  phoneTab: {
    fontSize: '0.8rem',
    fontWeight: 'normal',
    opacity: 0.6,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10
  } as CSSProperties,
  phoneTabContainer: {
    boxShadow: '0px 5px 9px -5px rgba(0,0,0,0.1)',
    minHeight: 20,
    backgroundColor: 'white',
    borderBottom: '1px solid #f0f0f0',
    paddingBottom: 10
  },
  phoneTabSelected: {
    fontSize: '0.8rem',
    fontWeight: 'normal',
    borderBottom: '3px solid purple',
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10
  } as CSSProperties
}


