import React, {FC} from 'react';
import {Equipment, Exercise, Workout} from "tlm-common";
import {EquipmentIcon} from "../icons/EquipmentIcon";
import {THEME_COLORS} from "../../assets/styles/themecolors";
import {Badge} from "react-bootstrap";


type EProps = {
  exercise: Exercise;
  index: number;
  active: boolean;
  onClick: (index: number) => void;
}

const ExerciseRow: FC<EProps> = ({exercise, index, active, onClick}) => {
  const isBreak = exercise.name === 'break';
  let backgroundColor = isBreak ? THEME_COLORS.ultralightPurple : 'transparent';
  if (active) {
    backgroundColor = '#fdb8e5';
  }
  return (
    <div className="d-flex justify-content-between border-bottom pr-2 pl-2"
         style={{backgroundColor}}
         onClick={() => onClick(index)}>
      <div style={{marginTop: 20}}>
        <p className="text-muted">{`${index+1}.`} {exercise.title || exercise.name || 'title missing!'}</p>
      </div>
      <div style={{marginTop: 10}}>
        {exercise.isCustom ? <Badge variant="info" style={{marginTop: 10}}>CUSTOM</Badge> : null }
        {exercise.equipment.map(e => <EquipmentIcon equipmentName={e as Equipment} size="sm"/>)}
        {exercise.optionalEquipment.map(e => <EquipmentIcon equipmentName={e as Equipment} size="sm" faded/>)}
      </div>
    </div>
  )
}

interface Props {
  workout?: Workout;
  index: number;
  onClick: (index: number) => void;
}

export const WorkoutOverview: FC<Props> = ({workout, index, onClick}) => {
  if (!workout) {
    return null;
  }
  return (
    <div className="shadow p-2">
      <h4 className="text-center text-secondary">Workout Overview</h4>
      { workout.name ? <p className="text-muted font-italic text-center">{workout.name}</p> : null }
      {workout.exercises.map((e, idx) =>
          <ExerciseRow exercise={e as Exercise}
                       index={idx}
                       active={idx === index}
                       onClick={onClick}
                       key={idx}/>
                       )}
    </div>
  );
};
