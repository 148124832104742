/*********************************
 File:       firebaseClients.ts
 Function:   Firestore API for clients
 Copyright:  Bertco LLC
 Date:       9/26/20
 Author:     mkahn
 **********************************/

import {firestore, auth} from "../../firebase/firebaseCore";
import {Client, TrainerRecommendation, Workout, WorkoutProgress, WorkoutStatus} from "tlm-common";

const clientCollection = firestore.collection('clients');

const getUid = () => {
  const uid = auth.currentUser?.uid;
  if (!uid) {
    throw new Error('No current firebase user');
  } else {
    return uid;
  }
}

export const getClientDocument = () => clientCollection.doc(getUid());

export async function getClient() {
  const doc = await getClientDocument().get();
  return {...doc.data()};
}

export async function updateClient(newFields: Partial<Client>) {
  // don't update the uid ever
  delete newFields.uid;
  return clientCollection.doc(getUid()).update(newFields);
}

export async function changeName(firstName: string, lastName: string) {
  await updateClient({firstName, lastName});
}

export async function getAllWorkouts() {
  const workouts = await clientCollection.doc(getUid()).collection('workouts').get();
  const workoutData: Workout[] = [];
  workouts.forEach(wo => workoutData.push({...wo.data(), id: wo.id} as Workout));
  return workoutData;
}

export async function getWorkout(workoutId: string) {
  const workout = await clientCollection.doc(getUid()).collection('workouts').doc(workoutId).get();
  return {...workout.data(), id: workoutId} as Workout;
}

export async function deleteWorkout(workoutId: string) {
  return clientCollection.doc(getUid()).collection('workouts').doc(workoutId).delete();
}

export function getWorkoutsCollection(){
  return clientCollection.doc(getUid()).collection('workouts');
}

export const updateWorkout = async (workoutId: string, workout: Partial<Workout>) => {
  return getWorkoutsCollection().doc(workoutId).update(workout);
}

export const setWorkoutFavorite = async (workout: Workout, favorite: boolean) => {
  return getWorkoutsCollection().doc(workout.id).update({ favorite })
}

export const markWorkoutComplete = async (workout: Workout) => {
  const uid = workout.id;
  const progress: WorkoutProgress = {
    status: WorkoutStatus.complete,
    exerciseIndex: 0,
    playbackTime: 0,
  };
  return getWorkoutsCollection()
    .doc(uid)
    .update({ progress, completedOn: new Date() });
};

let _workoutProgress: WorkoutProgress;

export const setCurrentWorkoutProgress = ( { status, playbackTime, exerciseIndex, rounds }:WorkoutProgress ) => {
  console.log('Ephemeral workout progress set');
  _workoutProgress = {
    status,
    exerciseIndex,
    playbackTime,
    rounds: rounds || 0
  }
}

export const commitWorkoutProgress = async (
  workout: Partial<Workout>) => {
  if (!workout || !workout.id) return;
  console.log('Persisting ephemeral workout progress');
  const uid = workout.id;
  return getWorkoutsCollection().doc(uid).update({ progress: _workoutProgress });
};

// Recommendations

export async function getAllRecommendations() {
  const recs = await clientCollection.doc(getUid()).collection('recommendations').get();
  const recsData: TrainerRecommendation[] = [];
  recs.forEach(wo => recsData.push({...wo.data(), id: wo.id} as TrainerRecommendation));
  return recsData;
}

export async function getRecommendation(recId: string) {
  const rec = await clientCollection.doc(getUid()).collection('recommendations').doc(recId).get();
  return {...rec.data(), id: recId} as TrainerRecommendation;
}

export async function deleteRecommendation(recId: string) {
  return clientCollection.doc(getUid()).collection('recommendations').doc(recId).delete();
}

export function getRecommendationsCollection(){
  return clientCollection.doc(getUid()).collection('recommendations');
}

export async function acceptWaiver() {
  return clientCollection.doc(getUid()).update({hasAcceptedLiabilityWaiver: true});
}
