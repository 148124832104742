import React, {CSSProperties, FC} from 'react';
import {THEME_COLORS} from "../../assets/styles/themecolors";
import logo from "../../assets/images/tlm-white.png";

interface Props {
  onClick: () => void;
}

export const StartWorkoutButton: FC<Props> = ({onClick}) => {
  return (
    <div style={styles.buttonField} onClick={onClick} className="animate__animated animate__pulse animate__infinite">
      <img src={logo} style={styles.logo}/>
      <p style={styles.text}>START</p>
    </div>
  );
};

const styles = {
  buttonField: {
    backgroundColor: THEME_COLORS.lightPurple,
    height: 120,
    width: 120,
    borderRadius: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  } as CSSProperties,
  logo: {
    width: 70,
    alignSelf: 'center'
  },
  text: {
    color: 'white',
    alignSelf: 'center',
    marginTop: 10,
  }
}
