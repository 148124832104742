import React, {CSSProperties, FC} from 'react';
import {GCSFile, Media} from "tlm-common";
import {Card} from 'react-bootstrap';
import {gcsUrlForFileName} from "../../services/gcf/gcsUrlForFileName";
import {BsFillPlayFill} from 'react-icons/bs';

interface Props {
  media: Media;
  onStart: (m: Media) => void;
}

export const MediaCard: FC<Props> = ({media, onStart}) => {

  // media.thumbnail can be GCSFile or {}
  if ((media.thumbnail as GCSFile).name === undefined) {
    console.warn('Empty media passed to MediaCard');
    return null;
  }

  return (
    <Card className="mr-sm-0 mr-md-2 mb-3">
      <div style={{position: 'relative'}}>
        <img src={gcsUrlForFileName((media.thumbnail as GCSFile).name)} className="w-100"/>
        <div style={styles.playHolder}>
          <BsFillPlayFill style={styles.playButton} onClick={() => onStart(media)}/>
        </div>
      </div>
      <Card.Body onClick={() => onStart(media)} style={{height: 200}}>
        <Card.Title>{media.name}</Card.Title>
        <Card.Text className="text-muted">
          {media.description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const styles = {
  playButton: {
    fontSize: 100,
    color: '#ffffff',
    alignSelf: 'center',
    marginBottom: 20,
    opacity: 0.8
  } as CSSProperties,
  heartFill: {
    color: '#de6363',
    fontSize: 20,
    position: 'absolute',
    top: 20,
    right: 20
  } as CSSProperties,
  heart: {
    color: 'white',
    fontSize: 20,
    position: 'absolute',
    top: 20,
    right: 20
  } as CSSProperties,
  playHolder: {
    //border: '1px solid red',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  } as CSSProperties
}
