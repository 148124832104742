import React, {CSSProperties, FC} from 'react';
import { Equipment } from 'tlm-common';
import kettlebellIcon from 'tlm-common/dist/assets/images/icon-kettlebell@3x.png';
import ballIcon from 'tlm-common/dist/assets/images/icon-ball@3x.png';
import bandIcon from 'tlm-common/dist/assets/images/icon-band@3x.png';
import loopedBandIcon from 'tlm-common/dist/assets/images/icon-looped-band@3x.png';
import bodyWeightIcon from 'tlm-common/dist/assets/images/icon-body-weight@3x.png';
import takeBreakIcon from 'tlm-common/dist/assets/images/icon-break@3x.png';
import chairIcon from 'tlm-common/dist/assets/images/icon-chair@3x.png';
import doorIcon from 'tlm-common/dist/assets/images/icon-door@3x.png';
import dumbbellIcon from 'tlm-common/dist/assets/images/icon-dumbbell@3x.png';
import foamRollerIcon from 'tlm-common/dist/assets/images/icon-foam-roller@3x.png';
import kettlebellOrDumbellIcon from 'tlm-common/dist/assets/images/icon-kettlebell-or-dumbbell@3x.png';
import matIcon from 'tlm-common/dist/assets/images/icon-mat@3x.png';
import pillowIcon from 'tlm-common/dist/assets/images/icon-pillow@3x.png';
import stepIcon from 'tlm-common/dist/assets/images/icon-step@3x.png';
import wallIcon from 'tlm-common/dist/assets/images/icon-wall@3x.png';
import {THEME_COLORS} from "../../assets/styles/themecolors";

const ICON_MAP: {[index: string]: string} = {
  'kettlebell': kettlebellIcon,
  'ball': ballIcon,
  'band':bandIcon,
  'looped-band': loopedBandIcon,
  'loopedBand': loopedBandIcon,
  'body-weight': bodyWeightIcon,
  'bodyWeight': bodyWeightIcon,
  'break': takeBreakIcon,
  'chair': chairIcon,
  'door': doorIcon,
  'dumbbell': dumbbellIcon,
  'foam-roller': foamRollerIcon,
  'foamRoller': foamRollerIcon,
  'kettlebell-or-dumbbell': kettlebellOrDumbellIcon,
  'kettlebellOrDumbbell': kettlebellOrDumbellIcon,
  'mat': matIcon,
  'pillow': pillowIcon,
  'step': stepIcon,
  'wall': wallIcon
}

const SIZE_MAP: {[index:string]: number} = {
  lg: 96,
  md: 48,
  sm: 32
}

interface Props {
  equipmentName: Equipment;
  size: 'lg' | 'md' | 'sm';
  backgroundColor?: string;
  faded?: boolean
}

export const EquipmentIcon: FC<Props> = ({equipmentName, size, backgroundColor = THEME_COLORS.ultralightPurple, faded}) => {

  const styles = getStyles(SIZE_MAP[size], backgroundColor, !!faded);

  return (
    <div style={styles.container} className="d-inline-flex flex-column justify-content-center m-1">
      <div className="align-self-center">
        <img src={ICON_MAP[equipmentName]} alt={equipmentName} style={styles.image}/>
      </div>
    </div>
  );
};

const getStyles = (size: number, color: string, faded: boolean) => ({
  container: {
    height: size,
    width: size,
    borderRadius: size/2,
    backgroundColor: color,
    opacity: faded ? 0.5 : 1
  } as CSSProperties,
  image: {
    width: size/1.5,
  } as CSSProperties
});
