import {firestore} from "../../firebase/firebaseCore";
import {Media} from "tlm-common";

const mediaCollection = firestore.collection('media');

export const getMediaDocument = (docId: string) => mediaCollection.doc(docId);

export async function getMedia(docId: string) {
  const doc = await getMediaDocument(docId).get();
  return {...doc.data(), id: docId};
}

export async function getAllMedia(type?: string) {
  let query =type ? mediaCollection.where('type', '==', type) : mediaCollection;
  const media = await query.get();
  const mediaData: Media[] = [];
  media.forEach(m => mediaData.push({...m.data(), id: m.id} as Media));
  return mediaData;
}
